
.social-icon {
  max-height: 15px;
}
.social-buttons {
  .column {
    padding: 5px 5px 0 5px;
    @media only screen and (min-width: 769px) {
      &:first-child {
        padding-left: 0 !important;
      }
      &:last-child {
        padding-right: 0 !important;
      }
    }
  }
}
